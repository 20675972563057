import React from "react"
import Imgix from "react-imgix"
import cx from "classnames"
import styles from "./stylesheets/clientsAndIndustries.module.scss"

export default function ClientLogoCloud({ segmentTitle, imageSrc, imageAlt }) {
  return (
    <div
      key={segmentTitle}
      className={cx(
        styles.logoCloud,
        "d-flex flex-row flex-wrap justify-content-around mt-5 px-3 pb-4 pt-2",
      )}
    >
      <div className="text-center">
        <div className={cx(styles.segmentTitle, "font-weight-bold mb-4")}>
          {segmentTitle}
        </div>
        <div>
          <Imgix src={imageSrc} alt={imageAlt} className="w-100" sizes="25vw" />
        </div>
      </div>
    </div>
  )
}
