import React from "react"
import styles from "./stylesheets/fullWidthVideo.module.scss"
import cx from "classnames"

export default function FullWidthVideo({ videoData }) {
  const videoId = videoData.hero_video.video_id.toString()
  const muted = videoData.sound === "On" ? "0" : "1"
  const loop = videoData.loop === "Yes" ? "1" : "0"
  const autoplay = videoData.auto_play === "Yes" ? "1" : "0"
  const embeddedUrl = `https://player.vimeo.com/video/${videoId}?autoplay=${autoplay}&loop=${loop}&title=0&byline=0&portrait=0&muted=${muted}&controls=0`

  return (
    <div className={styles.outerContainer}>
      <div className={cx(styles.innerContainer, styles.embedResponsive)}>
        <iframe
          title={videoData.hero_video.title}
          className={styles.embedResponsiveItem}
          src={embeddedUrl}
          frameBorder="0"
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  )
}
