import React from "react"
import ServiceCard from "./serviceCard"
import styles from "./stylesheets/companyServices.module.scss"
import cx from "classnames"

export default function CompanyServices({ services }) {
  return (
    <div className={cx(styles.topLevelContainer)}>
      <div className={cx(styles.servicesTitle, "mb-2 mt-3 text-center")}>
        Our Services
      </div>
      <div className="d-flex flex-row justify-content-center flex-wrap flex-xl-nowrap mx-2">
        {services.map((service) => (
          <ServiceCard key={service.service_name[0].text} service={service} />
        ))}
      </div>
    </div>
  )
}
