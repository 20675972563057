export const industriesInformation = [
  {
    title: "Dealerships",
    description:
      "An extensive national network that uses innovative technology to create custom solutions for you.",
    imageName: "industry-dealerships.png",
  },
  {
    title: "Auto Auctions",
    description:
      "Speed is key for auction moves–that’s why we’ve created a national network of carriers and terminals.",
    imageName: "industry-auto-auctions.png",
  },
  {
    title: "OEM",
    description:
      "Innovative technology that results in highly optimized solutions for you.",
    imageName: "industry-oem.png",
  },
  {
    title: "Individual",
    description: "We'll deliver your car where and when you want it.",
    imageName: "industry-individual.png",
  },
  {
    title: "Specialty",
    description:
      "Industry- leading insurance coverage so your car is protected at every turn.",
    imageName: "industry-specialty.png",
  },
  {
    title: "Rental Vehicles",
    description:
      "Exceptional capacity for the high velocity of rental vehicles moving year round.",
    imageName: "industry-rental-vehicles.png",
  },
  {
    title: "Corporate Fleets",
    description:
      "Utilizing a consultative approach to earn the trust of multi-million dollar remarketers.",
    imageName: "industry-corporate-fleet.png",
  },
  {
    title: "Financial Institutions",
    description:
      "Fully equipped to support your repossessions/inoperable vehicles or off-lease vehicles to and from auction.",
    imageName: "industry-financial-institutions.png",
  },
  {
    title: "Prototype Vehicles",
    description:
      "Private, secure transportation of prototype and concept vehicles.",
    imageName: "prototype.png",
  },
]
