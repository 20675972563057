import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import cx from "classnames"
import styles from "./stylesheets/clientsAndIndustries.module.scss"

const industryCardQuery = graphql`
  query highlightedIndustryQuery {
    allFile(filter: { extension: { eq: "png" } }) {
      edges {
        node {
          childImageSharp {
            fixed {
              src
            }
          }
          relativePath
        }
      }
    }
  }
`

const IndustryCardRenderer = ({ data, title, description, imageName }) => {
  const [isActive, setIsActive] = React.useState(false)
  const getImagePath = () => {
    if (!data.allFile) return null
    const imageData = data.allFile.edges.find(
      (edge) => edge.node.relativePath === imageName,
    )
    const imagePath = imageData.node.childImageSharp.fixed.src
    return imagePath
  }
  const backgroundStyle = {
    background: `url(${getImagePath()})`,
  }

  return (
    <div
      key={title}
      className={cx(
        styles.industryCard,
        isActive ? styles.blueEffect : styles.grayscaleEffect,
        "flex-column m-2 p-3 text-center text-white",
      )}
      style={backgroundStyle}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className={cx(styles.industryCardName, "mb-1 font-weight-bold")}>
        {title}
      </div>
      <div className={cx(styles.industryCardDescription, "mx-4")}>
        {description}
      </div>
    </div>
  )
}

export default function IndustryCard(props) {
  return (
    <StaticQuery
      query={`${industryCardQuery}`}
      render={withPreview(
        (data) => (
          <IndustryCardRenderer data={data} {...props} />
        ),
        industryCardQuery,
      )}
    />
  )
}
