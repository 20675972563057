import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import cx from "classnames"
import styles from "./stylesheets/bannerMessage.module.scss"

export default function BannerMessage({ bannerData }) {
  const callToAction =
    bannerData.call_to_action_text[0] && bannerData.call_to_action_text[0].text

  return (
    <div
      className={cx(
        styles.bannerTopLevel,
        {
          "bg-blue text-white": bannerData.banner_background === "Blue",
          "bg-green text-white": bannerData.banner_background === "Green",
          "bg-black text-white": bannerData.banner_background === "Black",
        },
        {
          "d-flex flex-column justify-content-center align-items-center text-center":
            bannerData.banner_orientation === "Vertical",
          "d-flex flex-column flex-md-row justify-content-around align-items-center":
            bannerData.banner_orientation === "Horizontal",
        },
        "py-2 px-3 px-md-5",
      )}
    >
      <div
        className={cx(styles.topLevelContainer, styles.richText, {
          "d-flex flex-column justify-content-center align-items-center text-center":
            bannerData.banner_orientation === "Vertical",
          "d-flex flex-column flex-md-row justify-content-around align-items-center":
            bannerData.banner_orientation === "Horizontal",
        })}
      >
        <RichText
          render={bannerData.banner_message}
          linkResolver={linkResolver}
        />
        {callToAction && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              bannerData.banner_link_destination &&
              bannerData.banner_link_destination.url
            }
            className={cx(
              styles.ctaButton,
              {
                "bg-blue text-white":
                  bannerData.call_to_action_button_background === "Blue",
                "bg-green text-white":
                  bannerData.call_to_action_button_background === "Green",
                "bg-black text-white":
                  bannerData.call_to_action_button_background === "Black",
                "bg-white text-black":
                  bannerData.call_to_action_button_background === "White",
              },
              {
                "mt-2": bannerData.banner_orientation === "Vertical",
                "ml-3 ml-md-5 mt-3 mt-md-0":
                  bannerData.banner_orientation === "Horizontal",
              },
              "py-2 px-3 text-center shadow",
            )}
          >
            {callToAction}
          </a>
        )}
      </div>
    </div>
  )
}
