import React from "react"
import { Button } from "react-bootstrap"
import ContactModal from "./contactModal"
import styles from "./stylesheets/taglineAndButton.module.scss"
import cx from "classnames"

export default function TaglineAndButton({ taglineData, isButtonBlue }) {
  const taglineText = taglineData.tagline[0] && taglineData.tagline[0].text
  const buttonText =
    taglineData.button_text[0] && taglineData.button_text[0].text
  const leadSource = taglineData.lead_source && taglineData.lead_source[0]?.text
  const [showContactModal, setShowContactModal] = React.useState(false)

  return (
    <div
      className={cx(
        styles.taglineSection,
        "d-flex flex-column align-items-center justify-content-center mx-auto",
      )}
    >
      <div
        className={cx(
          styles.taglineText,
          "font-weight-bold text-center mb-md-2",
        )}
      >
        {taglineText}
      </div>
      <div className="mt-4">
        <Button
          variant={isButtonBlue ? "primary" : "success"}
          onClick={() => setShowContactModal(true)}
          className={cx(styles.callToActionBtn, "py-3 px-4")}
          aria-label="Contact Modal Trigger"
        >
          {buttonText}
        </Button>
      </div>
      <ContactModal
        show={showContactModal}
        setShow={setShowContactModal}
        leadSource={leadSource}
        hideAdvanced={true}
        hideCompany={false}
        hideNumberOfVehicles={true}
      />
    </div>
  )
}
