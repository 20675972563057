import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import FullWidthImage from "../components/fullWidthImage"
import SolutionsPanel from "../components/solutionsPanel"
import ClientsAndIndustries from "../components/clientsAndIndustries"
import CompanyServices from "../components/companyServices"
import Tagline from "../components/tagline"
import TaglineAndButton from "../components/taglineAndButton"
import FullWidthVideo from "../components/fullWidthVideo"
import FullWidthText from "../components/fullWidthText"
import BannerMessage from "../components/bannerMessage"
import CarouselWithOverlay from "../components/carouselWithOverlay"
import SEO from "../components/seo"
import { standardizePrismicType } from "../utils/standardizePrismicType"

export const query = graphql`
  query HomepageSectionsQuery {
    prismic {
      allHomepages {
        edges {
          node {
            title
            homepage_meta_title
            homepage_meta_description
            body {
              ... on PRISMIC_HomepageBodyImage_carousel {
                fields {
                  carousel_image
                  overlay_paragraph
                  carousel_text_overlay
                  overlay_alignment
                  call_to_action_destination_url {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  call_to_action_button_text
                  button_color
                }
              }
              ... on PRISMIC_HomepageBodyFull_width_image {
                primary {
                  image
                }
                label
              }
              ... on PRISMIC_HomepageBodyClients___industries {
                type
                fields {
                  industry_segment
                  logo_cloud
                }
              }
              ... on PRISMIC_HomepageBodyServices {
                fields {
                  service_name
                  service_icon_while_active
                  service_icon
                  service_description
                }
              }
              ... on PRISMIC_HomepageBodyTagline {
                primary {
                  tagline
                }
              }
              ... on PRISMIC_HomepageBodyCall_to_action {
                primary {
                  button_text
                  tagline
                  lead_source
                }
              }
              ... on PRISMIC_HomepageBodySolutions_panel {
                fields {
                  caption_background
                  caption_placement
                  solution_caption
                  solution_image
                }
              }
              ... on PRISMIC_HomepageBodyHero_video1 {
                primary {
                  sound
                  loop
                  auto_play
                  hero_video
                }
              }
              ... on PRISMIC_HomepageBodyBanner_message {
                primary {
                  call_to_action_text
                  call_to_action_button_background
                  banner_message
                  banner_link_destination {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__ImageLink {
                      url
                    }
                  }
                  banner_background
                  banner_orientation
                }
              }
              ... on PRISMIC_HomepageBodyFull_width_text {
                primary {
                  rich_text
                }
              }
            }
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  const homepages = data.prismic.allHomepages.edges
  const page = homepages[0]
  if (!page) return null
  const prismicSections = page.node.body.map((section) =>
    standardizePrismicType(section),
  )
  if (!prismicSections) return null
  const metaTitle =
    page.node.homepage_meta_title[0]?.text || page.node.page_title[0]?.text
  const metaDescription = page.node.homepage_meta_description[0]?.text

  return (
    <Layout>
      <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
      <div>
        {prismicSections.map((prismicSection) => {
          let template
          switch (prismicSection.__typename) {
            case "PRISMIC_HomepageBodyFull_width_image":
              template = (
                <FullWidthImage
                  src={prismicSection.primary.image.url}
                  alt={prismicSection.primary.image.alt}
                />
              )
              break
            case "PRISMIC_HomepageBodyImage_carousel":
              template = (
                <CarouselWithOverlay carouselImages={prismicSection.fields} />
              )
              break
            case "PRISMIC_HomepageBodyClients___industries":
              template = (
                <ClientsAndIndustries
                  clientLogoCloudData={prismicSection.fields}
                />
              )
              break
            case "PRISMIC_HomepageBodyHero_video":
              template = null
              break
            case "PRISMIC_HomepageBodyServices":
              template = <CompanyServices services={prismicSection.fields} />
              break
            case "PRISMIC_HomepageBodyTagline":
              template = (
                <Tagline
                  taglineText={
                    prismicSection.primary.tagline[0] &&
                    prismicSection.primary.tagline[0].text
                  }
                />
              )
              break
            case "PRISMIC_HomepageBodyCall_to_action":
              template = (
                <TaglineAndButton taglineData={prismicSection.primary} />
              )
              break
            case "PRISMIC_HomepageBodySolutions_panel":
              template = <SolutionsPanel panelData={prismicSection.fields} />
              break
            case "PRISMIC_HomepageBodyHero_video1":
              template = <FullWidthVideo videoData={prismicSection.primary} />
              break
            case "PRISMIC_HomepageBodyBanner_message":
              template = <BannerMessage bannerData={prismicSection.primary} />
              break
            case "PRISMIC_HomepageBodyFull_width_text":
              template = (
                <FullWidthText richText={prismicSection.primary.rich_text} />
              )
              break
            default:
              template = null
          }
          return template
        })}
      </div>
    </Layout>
  )
}

export default Homepage
