import React from "react"
import { Carousel } from "react-bootstrap"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Imgix from "react-imgix"
import styles from "./stylesheets/carouselWithOverlay.module.scss"
import cx from "classnames"

export default function CarouselWithOverlay({ carouselImages }) {
  return (
    <Carousel interval={5000} indicators={false} controls={false} pause={false}>
      {carouselImages.map((imageData) => {
        const {
          carousel_image,
          overlay_paragraph,
          carousel_text_overlay,
        } = imageData
        const buttonText =
          imageData.call_to_action_button_text &&
          imageData.call_to_action_button_text[0]?.text
        const buttonColor = imageData.button_color
        const destinationURL = imageData.call_to_action_destination_url
        const leftAlignment = imageData.overlay_alignment === "Left"

        return (
          <Carousel.Item key={carousel_image.alt || carousel_image.url}>
            <Imgix
              className={cx(styles.carouselImage, "d-block w-100")}
              src={carousel_image.url}
              alt={carousel_image.alt}
              sizes="100vw"
            />
            <Carousel.Caption
              className={cx(
                styles.carouselCaption,
                "d-flex d-flex-row align-items-center justify-content-between pb-sm-3 p-md-0 w-100",
              )}
            >
              <div
                className={cx(
                  styles.carouselCaptionContainer,
                  !leftAlignment && "flex-row-reverse",
                  "d-flex w-100",
                )}
              >
                <div
                  className={cx(
                    !leftAlignment && "justify-self-end",
                    styles.carouselCaptionText,
                  )}
                >
                  {carousel_text_overlay && (
                    <div
                      className={cx(
                        styles.captionTextContainer,
                        styles.captionTextTitle,
                        !leftAlignment && "float-right text-right",
                      )}
                    >
                      <RichText
                        render={carousel_text_overlay}
                        linkResolver={linkResolver}
                      />
                    </div>
                  )}

                  {overlay_paragraph && (
                    <div>
                      <div
                        className={cx(
                          styles.captionTextContainer,
                          styles.captionTextSubcaption,
                          !leftAlignment && "float-right text-right",
                        )}
                      >
                        <RichText
                          render={overlay_paragraph}
                          linkResolver={linkResolver}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={cx(
                    styles.carouselCaptionButton,
                    "d-flex align-items-center ml-lg-3",
                  )}
                >
                  {buttonText && (
                    <OutboundLink
                      href={destinationURL?.url}
                      className={cx(styles.sectionCtaButton, {
                        "bg-blue border-blue text-white":
                          buttonColor === "Blue",
                        "bg-green border-green text-white":
                          buttonColor === "Green",
                        "bg-black border-black text-white":
                          buttonColor === "Black",
                        "bg-white border-white text-black":
                          buttonColor === "White",
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter"
                    >
                      {buttonText}
                    </OutboundLink>
                  )}
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}
