import React from "react"
import ClientLogoCloud from "./clientLogoCloud"
import cx from "classnames"
import styles from "./stylesheets/clientsAndIndustries.module.scss"

export default function Clients({ clientLogoCloudData }) {
  return (
    <div>
      <div className={cx(styles.clientIndustrySubheader, "text-center")}>
        {`We're proud to call some of the biggest companies in the industry our
        partners.`}
      </div>

      <div className="d-flex flex-row justify-content-center flex-md-nowrap">
        {clientLogoCloudData.map((logoCloudData) => {
          const segmentTitle = logoCloudData.industry_segment[0].text
          const imageSrc = logoCloudData.logo_cloud.url
          const imageAlt = logoCloudData.logo_cloud.alt
          return (
            <ClientLogoCloud
              key={segmentTitle}
              segmentTitle={segmentTitle}
              imageSrc={imageSrc}
              imageAlt={imageAlt}
            />
          )
        })}
      </div>
    </div>
  )
}
