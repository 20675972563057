import React from "react"
import IndustryCard from "./industryCard"
import { industriesInformation } from "../constants.js"
import cx from "classnames"
import styles from "./stylesheets/clientsAndIndustries.module.scss"

export default function Industries() {
  return (
    <div>
      <div className={cx(styles.clientIndustrySubheader, "text-center")}>
        From auctions to dealerships, we service every mile of the car-hauling
        industry.
      </div>

      <div
        className={cx(
          styles.industriesContainer,
          "d-flex flex-row justify-content-center align-items-center flex-wrap my-5",
        )}
      >
        {industriesInformation.map((industryInfo) => (
          <IndustryCard
            key={industryInfo.title}
            title={industryInfo.title}
            description={industryInfo.description}
            imageName={industryInfo.imageName}
          />
        ))}
      </div>
    </div>
  )
}
