import React from "react"
import styles from "./stylesheets/taglineAndButton.module.scss"
import cx from "classnames"

export default function Tagline({ taglineText }) {
  if (!taglineText) return null

  return (
    <div
      className={cx(
        styles.taglineSection,
        "d-flex flex-column align-items-center justify-content-center m-auto",
      )}
    >
      <div className={cx(styles.taglineText, "font-weight-bold text-center")}>
        {taglineText}
      </div>
    </div>
  )
}
