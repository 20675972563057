import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/companyServices.module.scss"
import cx from "classnames"

export default function ServiceCard({ service }) {
  const [isActive, setIsActive] = React.useState(false)
  const {
    service_name,
    service_icon_while_active,
    service_icon,
    service_description,
  } = service
  const displayName = service_name[0] && service_name[0].text
  const displayDescription =
    service_description[0] && service_description[0].text
  const iconUrl = isActive ? service_icon_while_active.url : service_icon.url
  const iconAlt = isActive ? service_icon_while_active.alt : service_icon.alt

  return (
    <div
      className={cx(styles.serviceCard, "px-1 mb-2 text-center")}
      onMouseOver={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <Imgix
        src={iconUrl}
        alt={iconAlt}
        className={styles.serviceCardImage}
        height={200}
        width={200}
      />
      <div className={cx(styles.serviceCardTitle, "pb-1 px-1")}>
        {displayName}
      </div>
      <div className="px-2">{displayDescription}</div>
    </div>
  )
}
